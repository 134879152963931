export const danish = () => {
  return {
    type: "DANISH",
  };
};

export const english = () => {
  return {
    type: "ENGLISH",
  };
};
